import React, { useEffect, useState } from 'react'

import style from './Popup.module.scss'

export const Popup = props => {
	const text = props.text

	let [isOpen, setIsOpen] = useState(null)
	useEffect(() => {
		setIsOpen(props.open)
	}, [props])
	const [isClickedClose, setIsClickedClose] = useState(false)
	const handleClosePopup = () => {
		setIsClickedClose(false)
		setIsOpen(false)
	}

	return (
		<div
			className={
				isClickedClose || isOpen ? style.wrapperPopup : style.wrapperPopupNone
			}
		>
			<div className={style.blockPopup}>
				<h2>{text}</h2>
			</div>
			<button
				onClick={() => handleClosePopup()}
				className={style.closeBtn}
			></button>
		</div>
	)
}
