import axios from 'axios'
import { useEffect, useRef, useState } from 'react'
import { LoaderUpload } from '../LoaderUpload/LoaderUpload.jsx'
import ProgressBar from '../ProgressBar/ProgressBar.jsx'
import { Popup } from '../popup/Popup.jsx'
import style from './VideoPopup.module.scss'
const FileUpload = () => {
	const [videoFile, setVideoFile] = useState(null)
	const [audioFile, setAudioFile] = useState(null)
	const [generateState, setGenerateState] = useState(false)
	const [videoProcessed, setVideoProcessed] = useState(null)
	const [isBusy, setIsBusy] = useState(false)

	const [videoFileName, setVideoFileName] = useState('')
	const [audioFileName, setAudioFileName] = useState('')
	const [videoUploadStatus, setVideoUploadStatus] = useState('')
	const [audioUploadStatus, setAudioUploadStatus] = useState('')

	const handleVideoChange = event => {
		const file = event.target.files[0]
		setVideoFile(file)
		setVideoFileName(file ? file.name : '')
	}

	const handleAudioChange = event => {
		const file = event.target.files[0]
		setAudioFile(file)
		setAudioFileName(file ? file.name : '')
	}

	const handleUpload = async event => {
		event.preventDefault()

		if (!videoFile || !audioFile) {
			return
		}

		const processVideoAndAudio = async (filename, input_audio) => {
			try {
				const formData = new FormData()

				formData.append('filename', filename)
				formData.append('input_audio', input_audio)
				const response = await axios.post(
					'http://46.183.216.106:27000/process',
					formData,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
							'Access-Control-Allow-Origin': '*',
						},
					}
				)

				if (response.data === 'Processing started') {
					setVideoProcessed(false)
					await checkLongpollStatus()
				}
				return response.data
			} catch (error) {
				setTimeout(() => processVideoAndAudio(filename, input_audio), 1000)
				return error
			}
		}

		const checkLongpollStatus = async () => {
			try {
				const response = await axios.get(
					'http://46.183.216.106:27000/longpoll_status'
				)
				if (response.data.status === 'no processing') {
					setVideoProcessed(true)
				} else {
					setVideoProcessed(false)

					setTimeout(checkLongpollStatus, 1000)
				}
			} catch (error) {
				setTimeout(checkLongpollStatus, 1000)
			}
		}
		const checkStatus = async () => {
			try {
				const result = await axios.get(
					'http://46.183.216.106:27000/check_status'
				)

				return result.data
			} catch (error) {
				return error
			}
		}

		const uploadFile = async (file, url, setStatus) => {
			const formData = new FormData()
			formData.append('file', file)

			try {
				setStatus('pending')
				const response = await axios.post(url, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
						'Access-Control-Allow-Origin': '*',
					},
				})
				setStatus('успешно')
				return response.data
			} catch (error) {
				setStatus(`Ошибка загрузки ${file.name}`)
				return `Ошибка загрузки ${file.name}`
			}
		}

		const videoUploadResult = await uploadFile(
			videoFile,
			'http://46.183.216.106:27000/upload_video',
			setVideoUploadStatus
		)
		const audioUploadResult = await uploadFile(
			audioFile,
			'http://46.183.216.106:27000/upload_audio',
			setAudioUploadStatus
		)
		const statusBusyBot = await checkStatus()

		if (statusBusyBot.status === 'processing') {
			setIsBusy(true)
		}
		if (statusBusyBot.status === 'no processing') {
			setGenerateState(true)
			setIsBusy(false)
			await processVideoAndAudio(videoFileName, audioFileName)
		}

		setVideoUploadStatus(`Видео: ${videoUploadResult}`)
		setAudioUploadStatus(`Аудио: ${audioUploadResult}`)
	}

	const fileInputVideoRef = useRef(null)
	const fileInputAudioRef = useRef(null)

	const handleClick = ref => {
		ref.current.click()
	}

	const HandleDownload = () => {
		if (videoProcessed === true) {
			const downloadUrl = `http://46.183.216.106:27000/download/${videoFileName}`
			window.location.href = downloadUrl
		}
	}
	useEffect(() => {
		if (false === videoProcessed) {
			const unload = event => {
				event.preventDefault()
				event.returnValue = ''
				return ''
			}

			window.addEventListener('beforeunload', unload)
			return () => window.removeEventListener('beforeunload', unload)
		}
	}, [videoProcessed])

	return (
		<div className={style.wrapperBlock}>
			{isBusy && (
				<Popup
					open={isBusy}
					text='AI занят на данный момент обработкой другого видео. Пожалуйста, подождите.'
				/>
			)}
			<h1>Загрузить видео и аудио</h1>
			<form onSubmit={handleUpload}>
				<div className={style.blockInput}>
					<label className={style.titleBlock}>Загрузите видео</label>
					<>
						<button
							className={style.uploadButton}
							onClick={() => handleClick(fileInputVideoRef)}
						>
							Загрузить видео
						</button>
						<input
							ref={fileInputVideoRef}
							id='video'
							type='file'
							onChange={handleVideoChange}
							accept='video/*'
							style={{ display: 'none' }}
						/>
					</>
					{videoFileName && (
						<p className={style.description}>
							Выбранное видео: {videoFileName}
						</p>
					)}
					{videoUploadStatus === 'pending' ? <LoaderUpload /> : ''}
				</div>
				<div className={style.blockInput}>
					<label className={style.titleBlock}>Загрузите аудио</label>
					<>
						<button
							className={style.uploadButton}
							onClick={() => handleClick(fileInputAudioRef)}
						>
							Загрузить аудио
						</button>
						<input
							ref={fileInputAudioRef}
							id='audio'
							type='file'
							onChange={handleAudioChange}
							accept='audio/*'
							style={{ display: 'none' }}
						/>
					</>
					{audioFileName && (
						<p className={style.description}>
							Выбранное аудио: {audioFileName}
						</p>
					)}
					{audioUploadStatus === 'pending' ? <LoaderUpload /> : ''}
				</div>
				{!generateState && (
					<div className={style.wrapperBtnGenerate}>
						<button className={style.btnGenerate} type='submit'>
							Генерировать
						</button>
					</div>
				)}
			</form>
			{videoProcessed && (
				<video
					src={`http://46.183.216.106:27000/download/${videoFileName}`}
					controls
				></video>
			)}
			<div>{videoProcessed === false && <ProgressBar />}</div>
			<button
				className={
					videoProcessed === true ? style.btnGenerate : style.btnGenerateBlock
				}
				onClick={HandleDownload}
			>
				Скачать
			</button>
		</div>
	)
}

export default FileUpload
