import VideoHandler from './pages/VideoHandler/VideoHandler'
import './scss/index.scss'

function App() {
	return (
		<div className='App'>
			<main>
				<VideoHandler />
			</main>
		</div>
	)
}

export default App
