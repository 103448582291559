import { ring } from 'ldrs'
import React from 'react'

const ProgressBar = () => {
	ring.register()
	return (
		<div>
			<l-ring
				size='40'
				stroke='5'
				bg-opacity='0'
				speed='2'
				color='#AAB3C8'
			></l-ring>
		</div>
	)
}

export default ProgressBar
