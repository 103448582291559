import { zoomies } from 'ldrs'
import React from 'react'

export const LoaderUpload = () => {
	zoomies.register()
	return (
		<div>
			<l-zoomies
				size='80'
				stroke='5'
				bg-opacity='0.3'
				speed='1.4'
				color='#fff'
			></l-zoomies>
		</div>
	)
}
