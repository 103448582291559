import VideoBlock from '../../features/ui/VideoBlock/VideoBlock'

const VideoHandler = () => {
	return (
		<div>
			<VideoBlock />
		</div>
	)
}

export default VideoHandler
